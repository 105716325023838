(function ($) {
    'use strict';

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('#cookie-message').on('click', function () {
        $('.cookies').animate({bottom:'-100px'}, 'slow', 'easeInOutExpo', function () {
            Cookies.set('cookie-message', 'true', {expires: 365, sameSite: 'lax'});
            $(this).remove();
        })
    });

    if (Cookies.get('cookie-message')) {
        $('.cookies').remove();
    }

    if ($('.messages').length) {
        setTimeout(function () {
            $('.messages').slideUp('fast', function () {
                $(this).remove();
            });
        }, 5000);
    }

    if ($('.errors').length) {
        setTimeout(function () {
            $('.errors').slideUp('fast', function () {
                $(this).remove();
            });
        }, 5000);
    }

    var $window = $(window);

    // :: Preloader Active Code
    $window.on('load', function () {
        $('#preloader').fadeOut('slow', function () {
            $(this).remove();
        });
    });

    // :: Search Form Active
    var searchbtnI = $(".searchbtn i");
    var searchbtn = $(".searchbtn");

    searchbtnI.addClass('fa-search');
    searchbtn.on('click', function () {
        $("body").toggleClass('search-close');
        searchbtnI.toggleClass('fa-times');
    });

    // :: More Filter Active Code
    $("#moreFilter").on('click', function () {
        $(".search-form-second-steps").slideToggle('1000');
    });

    // :: Nav Active Code
    if ($.fn.classyNav) {
        $('#southNav').classyNav({
            theme: 'dark'
        });
    }

    // :: Sticky Active Code
    if ($.fn.sticky) {
        $("#stickyHeader").sticky({
            topSpacing: 0
        });
    }

    // :: Tooltip Active Code
    if ($.fn.tooltip) {
        $('[data-toggle="tooltip"]').tooltip()
    }

    // :: Nice Select Active Code
    if ($.fn.niceSelect) {
        $('select').niceSelect();
    }

    // :: Owl Carousel Active Code
    if ($.fn.owlCarousel) {

        var welcomeSlide = $('.hero-slides');

        welcomeSlide.owlCarousel({
            items: 1,
            margin: 0,
            loop: true,
            nav: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            dots: true,
            autoplay: true,
            autoplayTimeout: 5000,
            smartSpeed: 1000
        });

        welcomeSlide.on('translate.owl.carousel', function () {
            var slideLayer = $("[data-animation]");
            slideLayer.each(function () {
                var anim_name = $(this).data('animation');
                $(this).removeClass('animated ' + anim_name).css('opacity', '0');
            });
        });

        welcomeSlide.on('translated.owl.carousel', function () {
            var slideLayer = welcomeSlide.find('.owl-item.active').find("[data-animation]");
            slideLayer.each(function () {
                var anim_name = $(this).data('animation');
                $(this).addClass('animated ' + anim_name).css('opacity', '1');
            });
        });

        $("[data-delay]").each(function () {
            var anim_del = $(this).data('delay');
            $(this).css('animation-delay', anim_del);
        });

        $("[data-duration]").each(function () {
            var anim_dur = $(this).data('duration');
            $(this).css('animation-duration', anim_dur);
        });

        // Dots Showing Number
        var dot = $('.hero-slides .owl-dot');

        dot.each(function () {
            var dotnumber = $(this).index() + 1;
            if (dotnumber <= 9) {
                $(this).html('0').append(dotnumber);
            } else {
                $(this).html(dotnumber);
            }
        });

        $('.testimonials-slides').owlCarousel({
            items: 3,
            margin: 50,
            loop: true,
            center: true,
            nav: true,
            navText: ['<i class="ti-angle-left"></i>', '<i class="ti-angle-right"></i>'],
            dots: false,
            autoplay: true,
            autoplayTimeout: 5000,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1
                },
                576: {
                    items: 3
                }
            }
        });

        $('.featured-properties-slides, .single-listings-sliders').owlCarousel({
            items: 1,
            margin: 0,
            loop: true,
            autoplay: true,
            autoplayTimeout: 5000,
            smartSpeed: 1000,
            nav: true,
            navText: ['<i class="ti-angle-left"></i>', '<i class="ti-angle-right"></i>']
        });
    }

    // :: CounterUp Active Code
    if ($.fn.counterUp) {
        $('.counter').counterUp({
            delay: 10,
            time: 2000
        });
    }

    // :: ScrollUp Active Code
    if ($.fn.scrollUp) {
        $.scrollUp({
            scrollSpeed: 1000,
            easingType: 'easeInOutQuart',
            scrollText: '<i class="fa fa-angle-up" aria-hidden="true"></i>'
        });
    }

    // :: PreventDefault a Click
    $("a[href='#']").on('click', function ($) {
        $.preventDefault();
    });

    // :: wow Active Code
    if ($window.width() > 767) {
        new WOW().init();
    }

    // :: Slider Range
    $('.slider-range-price, .slider-range-area').each(function () {
        var min = jQuery(this).data('min');
        var max = jQuery(this).data('max');
        var unit = jQuery(this).data('unit');
        var value_min = jQuery(this).data('value-min');
        var value_max = jQuery(this).data('value-max');
        var step = parseInt(jQuery(this).data('step'));
        var t = $(this);
        $(this).slider({
            range: true,
            min: min,
            max: max,
            step: step,
            values: [value_min, value_max],
            slide: function (event, ui) {
                t.find('input[type="hidden"]').remove();
                var result = parseInt(ui.values[0]).toLocaleString() + unit + ' - ' + parseInt(ui.values[1]).toLocaleString() + unit;
                t.closest('.slider-range').find('.range').html(result);
                if (t.hasClass('slider-range-area')) {
                    t.append("<input type='hidden' name='range_min' value='" + ui.values[0] + "'>");
                    t.append("<input type='hidden' name='range_max' value='" + ui.values[1] + "'>");
                } else {
                    t.append("<input type='hidden' name='price_min' value='" + ui.values[0] + "'>");
                    t.append("<input type='hidden' name='price_max' value='" + ui.values[1] + "'>");
                }
            }
        });
    });

    $('input[name="cv_file"]').on('change', function () {
        $('.file-count').remove();
        if ($(this)[0].files.length) {
            $(this).closest('div').append('<span class="badge badge-danger file-count">' + $(this)[0].files.length + '</span>');
        } else {
            $('.file-count').fadeOut('slow');
        }
    });

    $('#contact-form, #newsletter-form, #career-form').on('submit', function (e) {
        e.preventDefault();
        var elm = $(this).attr('id');
        sendForm('#' + elm);
    });

    $('input, textarea, select').on('change keypress keydown keyup', function () {
        $('.nice-select').removeClass('error');
        $(this).removeClass('error');
    })

})(jQuery);

function sendForm(element, async) {
    var ajaxform = $(element);
    var url = ajaxform.attr('action');
    var type = ajaxform.attr('method');
    var data = new FormData(ajaxform[0]);
    var oldButtonContent = ajaxform.find('button').text();
    if(async !== false) async = true;

    $.ajax({
        url: url,
        type: type,
        data: data,
        async: async,
        contentType: false,
        processData: false,
        statusCode: {
            500: function(data) {
                notify('Chyba v skripte na serveri', 'error');
                ajaxform.find('button').prop('disabled', false).html(oldButtonContent);
                ajaxform.find('input, textarea, select').prop('disabled', false);
                $('select').niceSelect('update');
            },

            422: function (data) {
                var warnings = data.responseJSON;

                $.each(warnings.errors, function (key, value) {
                    notify(value, 'warning');
                    ajaxform.find('[name="' + key + '"]').addClass('error');
                    ajaxform.find('[name="' + key + '"]').parent('.form-group').find('.nice-select').addClass('error');
                });

                ajaxform.find('button').prop('disabled', false).html(oldButtonContent);
                ajaxform.find('input, textarea, select').prop('disabled', false);
                $('select').niceSelect('update');
            }
        },
        beforeSend: function() {
            ajaxform.find('input, textarea, select').prop('disabled', true);
            $('select').niceSelect('update');
            ajaxform.find('button').prop('disabled', true).html('<i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i> Odosielam...');
        },
        success: function (response) {

            if (response.type == 'success') {
                notify(response.message, 'success');
            } else {
                notify(response.message, 'error');
            }
        }
    }).done(function () {
        ajaxform.each(function () {
            this.reset();
            $(this).find('button').prop('disabled', false).html(oldButtonContent);
            $(this).find('input, textarea, select').prop('disabled', false);
            $('select').niceSelect('update');
            ajaxform.find('.slider-range-area').slider("values", 0, 10);
            ajaxform.find('.slider-range-area').slider("values", 1, 10000);
            ajaxform.find('.slider-range-price').slider("values", 0, 1);
            ajaxform.find('.slider-range-price').slider("values", 1, 999999);
            ajaxform.find('.slider-range-area').closest('.slider-range').find('.range').html('10 m2 - 10 000 m2');
            ajaxform.find('.slider-range-price').closest('.slider-range').find('.range').html('1 000 € - 999 000 €');
            ajaxform.find('input[type="hidden"]').remove();
            var idModal = ajaxform.closest('.modal').attr('id');
            $('#' + idModal).modal('hide');
            $('.file-count').fadeOut('slow');
        });
    });
}

function notify(message, type, duration, hideNotice) {

    PNotify.defaultModules.set(PNotifyBootstrap4, {});
    PNotify.defaultModules.set(PNotifyFontAwesome4, {});
    PNotify.defaults.maxTextHeight = null;
    PNotify.defaults.modules = new Map([
        ...PNotify.defaultModules,
        [PNotifyAnimate, {
            inClass: 'zoomInLeft',
            outClass: 'zoomOutRight'
        }]
    ]);

    if (!duration) duration = 5000;
    if (hideNotice !== false) hideNotice = true;

    if (type == 'info') {
        PNotify.info({
            title: 'Info',
            text: message,
            icon: 'fa fa-info',
            delay: duration,
            hide: hideNotice
        });
    }

    if (type == 'success') {
        PNotify.success({
            title: 'Úspešne',
            text: message,
            icon: 'fa fa-check',
            delay: duration,
            hide: hideNotice
        });
    }

    if (type == 'warning') {
        PNotify.alert({
            title: 'Upozornenie',
            text: message,
            icon: 'fa fa-exclamation-triangle',
            delay: duration,
            hide: hideNotice
        });
    }

    if (type == 'error') {
        PNotify.error({
            title: 'Chyba',
            text: message,
            icon: 'fa fa-ban',
            delay: duration,
            hide: hideNotice
        });
    }

}
